define("iosxpert-web/components/item-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qWVUxGqS",
    "block": "[[[10,\"aside\"],[15,0,[29,[\"item-list \",[52,[30,0,[\"isIndexRoute\"]],\"index-route\"],\" \"]]],[12],[1,\"\\n\"],[18,1,[[28,[37,2],null,[[\"Header\",\"Body\",\"Footer\"],[[50,\"item-list/header\",0,null,null],[50,\"item-list/body\",0,null,null],[50,\"item-list/footer\",0,null,null]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"if\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "iosxpert-web/components/item-list/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});