define("iosxpert-web/components/general/label-view/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b0Eyrb5X",
    "block": "[[[18,3,[[28,[37,1],null,[[\"Item\"],[[50,\"general/label-view/label-item\",0,null,[[\"model\",\"isEditing\"],[[30,1],[30,2]]]]]]]]]],[\"@model\",\"@isEditing\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "iosxpert-web/components/general/label-view/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});