define("iosxpert-web/components/utils/full-size-background/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PbvaA2Q2",
    "block": "[[[10,0],[14,0,\"fixed top-0 min-h-screen w-full w-auto bg-fixed bg-no-repeat bg-cover bg-left-top\"],[15,5,[30,0,[\"style\"]]],[12],[13]],[],false,[]]",
    "moduleName": "iosxpert-web/components/utils/full-size-background/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});