define("iosxpert-web/components/item-list/header/subtitle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JWtKK4GD",
    "block": "[[[10,\"h2\"],[14,0,\"mt-1 text-sm text-gray-600 rounded-sm bg-gray-100\"],[12],[18,1,null],[13]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "iosxpert-web/components/item-list/header/subtitle/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});