define("iosxpert-web/components/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WS/5OeDA",
    "block": "[[[11,\"header\"],[17,1],[12],[1,\"\\n    \"],[18,2,[[28,[37,1],null,[[\"Left\",\"Center\",\"Right\"],[[50,\"header/left\",0,null,null],[50,\"header/center\",0,null,null],[50,\"header/right\",0,null,null]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "iosxpert-web/components/header/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});