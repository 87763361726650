define("iosxpert-web/components/utils/table/row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J2Qrcq7B",
    "block": "[[[41,[30,0,[\"isHidden\"]],[[[11,0],[24,0,\"table-row\"],[17,1],[12],[1,\"\\n    \"],[18,4,[[28,[37,2],null,[[\"Cell\",\"LabelCell\"],[[50,\"utils/table/row/cell\",0,null,[[\"model\",\"isEditing\"],[[30,2],[30,3]]]],[50,\"utils/table/row/label-cell\",0,null,[[\"model\",\"isEditing\"],[[30,2],[30,3]]]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[]],null]],[\"&attrs\",\"@model\",\"@isEditing\",\"&default\"],false,[\"if\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "iosxpert-web/components/utils/table/row/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});