define("iosxpert-web/components/sidebar/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N7DqWuYg",
    "block": "[[[8,[39,0],[[24,0,\"navigation-item\"],[16,\"data-test-sidebar-name\",[29,[[30,1]]]]],[[\"@route\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n  \"],[10,1],[14,0,\"group-hover:text-gray-500 mr-3 h-6 w-6\"],[12],[1,\"\\n  \"],[8,[39,1],[[16,\"color\",[29,[[30,3]]]]],[[\"@icon\",\"@prefix\"],[[29,[[30,4]]],\"fad\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,5,[\"isExtended\"]],[[[1,\"    \"],[1,[30,1]],[1,\"\\n\"]],[]],null]],[]]]]]],[\"@title\",\"@route\",\"@color\",\"@icon\",\"@sidebar\"],false,[\"link-to\",\"fa-icon\",\"if\"]]",
    "moduleName": "iosxpert-web/components/sidebar/item/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});