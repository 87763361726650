define("iosxpert-web/mirage/scenarios/unit-tests", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server) {
    server.db.loadData({
      'tasks': [{
        'id': 1000,
        'title': 'Test Task'
      }],
      'contacts': [{
        'id': 1000,
        'firstName': 'Thomas',
        'lastName': 'Bartelmess',
        'taskIds': [1000],
        'emails': [{
          'label': 'Work',
          'address': 'thomas@bartelmess.io'
        }],
        'addresses': [{
          'label': "Billing",
          'street': "Test Street",
          'city': "Test City",
          'state': "Test State"
        }]
      }]
    });
  }
});