define("iosxpert-web/components/general/card/information/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PaoINHXn",
    "block": "[[[8,[39,0],null,[[\"@image\",\"@imageIcon\",\"@text\"],[[30,1,[\"image\"]],[30,2],[30,1,[\"name\"]]]],null],[1,\"\\n\"],[10,0],[14,0,\"flex pt-2\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"mx-auto\"],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@category\"],[[30,1,[\"dayliteCategory\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"flex flex-wrap justify-center mt-2\"],[12],[1,\"\\n    \"],[18,3,[[28,[37,3],null,[[\"InfoItem\"],[[50,\"general/card/information/info-item\",0,null,[[\"model\"],[[30,1]]]]]]]]],[1,\"\\n\"],[13]],[\"@model\",\"@imageIcon\",\"&default\"],false,[\"general/views/image-and-text\",\"utils/daylite-category\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "iosxpert-web/components/general/card/information/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});