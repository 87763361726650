define("iosxpert-web/components/general/label-view/label-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bNPDUR9j",
    "block": "[[[41,[30,1],[[[41,[30,0,[\"labelItems\"]],[[[1,\"\\n\"]],[]],null]],[]],[[[1,\"    \"],[18,2,null],[1,\"\\n\"]],[]]]],[\"@isEditing\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "iosxpert-web/components/general/label-view/label-item/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});