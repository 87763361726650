define("iosxpert-web/components/utils/role-linking-group/project-linking-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oUYijaWj",
    "block": "[[[1,\"\\n\"],[10,0],[14,0,\"flex-shrink-0 px-4 flex border rounded-full bg-gray-100 border-gray-200\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"w-full flex items-center\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"flex-none mx-auto h-6 w-6\"],[12],[1,\"\\n            \"],[8,[39,0],null,[[\"@icon\",\"@prefix\"],[\"hammer\",\"fad\"]],null],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"flex-grow ml-3\"],[12],[1,\"\\n            \"],[10,2],[14,0,\"text-sm text-gray-700\"],[12],[1,\"\\n                \"],[1,[30,1]],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[8,[39,0],null,[[\"@icon\",\"@prefix\",\"@rotation\"],[\"chevron-up\",\"fad\",90]],null],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@name\"],false,[\"fa-icon\"]]",
    "moduleName": "iosxpert-web/components/utils/role-linking-group/project-linking-field/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});