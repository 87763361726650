define("iosxpert-web/components/object-viewer/footer/actions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kXtQbit+",
    "block": "[[[10,0],[14,0,\"flex\"],[12],[1,\"\\n\"],[8,[39,0],[[24,0,\"flex md:hidden\"]],[[\"@action\",\"@title\"],[[30,1],\"Back\"]],null],[1,\"\\n\"],[10,0],[14,0,\"w-full flex justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3\"],[12],[1,\"\\n\"],[41,[30,2],[[[1,\" \\n\"],[41,[30,3],[[[1,\"    \"],[8,[39,0],null,[[\"@action\",\"@title\"],[[30,4],\"Save Changes\"]],null],[1,\"\\n    \"],[8,[39,0],null,[[\"@action\",\"@title\"],[[30,5],\"Cancel\"]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,0],null,[[\"@action\",\"@title\"],[[30,4],\"Edit\"]],null],[1,\"\\n\"]],[]]]],[]],null],[13],[1,\"\\n\"],[13]],[\"@backward\",\"@showEdit\",\"@isEditing\",\"@toggleEditing\",\"@cancelEditing\"],false,[\"object-viewer/footer/actions/button\",\"if\"]]",
    "moduleName": "iosxpert-web/components/object-viewer/footer/actions/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});