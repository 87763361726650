define("iosxpert-web/components/main/title-hero/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FeOvjn+n",
    "block": "[[[10,0],[14,0,\"border-blue-400 p-8 border-t-8 bg-white rounded-lg shadow-lg w-full mt-12 mx-3\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"font-bold text-4xl mb-2\"],[12],[1,[30,1]],[13],[1,\"\\n  \"],[10,0],[12],[13],[1,\"\\n\\n  \"],[10,2],[14,0,\"text-gray-700 text-base\"],[12],[1,[30,2]],[13],[1,\"\\n\"],[13]],[\"@title\",\"@details\"],false,[]]",
    "moduleName": "iosxpert-web/components/main/title-hero/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});