define("iosxpert-web/components/object-viewer/header/actions/button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xn2fS257",
    "block": "[[[11,\"button\"],[16,0,[29,[\"header-button header-button-\",[30,1],\" inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100\"]]],[16,\"disabled\",[30,0,[\"disabled\"]]],[24,4,\"button\"],[4,[38,0],[\"click\",[28,[37,1],[[30,0,[\"handleClick\"]]],null]],null],[12],[1,\"\\n\"],[1,[30,2]],[1,\"\\n\"],[13]],[\"@type\",\"@title\"],false,[\"on\",\"fn\"]]",
    "moduleName": "iosxpert-web/components/object-viewer/header/actions/button/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});