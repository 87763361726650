define("iosxpert-web/components/general/card/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AgsKfSQ2",
    "block": "[[[10,0],[14,0,\"flex justify-center font-bold text-gray-400 mb-4\"],[12],[1,\"\\n  \"],[10,\"h2\"],[14,0,\"text-lg text-center\"],[12],[1,[30,1]],[13],[1,\"\\n\"],[13]],[\"@title\"],false,[]]",
    "moduleName": "iosxpert-web/components/general/card/header/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});