define("iosxpert-web/components/utils/role-linking-group/contact-linking-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hh5CM6Zx",
    "block": "[[[8,[39,0],[[24,0,\"flex-none bg-gray-100 hover:bg-gray-50 py-3 rounded-lg float-left w-full h-full\"]],[[\"@route\",\"@model\"],[\"main.people.show\",[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],[[24,0,\"h-12 w-12 rounded-full float-left mx-4\"]],[[\"@image\",\"@imageIcon\",\"@size\"],[[30,2],[30,3],\"3x\"]],null],[1,\"\\n    \"],[10,\"th\"],[14,0,\"text-sm\"],[12],[1,\"\\n    \"],[10,\"ul\"],[12],[1,\"\\n        \"],[10,\"li\"],[14,0,\"font-bold text-gray-600\"],[12],[1,[30,4]],[13],[1,\"\\n\"],[41,[30,5],[[[1,\"        \"],[10,\"li\"],[14,0,\"font-light text-gray-500\"],[12],[1,[30,5]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]]],[\"@contact\",\"@image\",\"@imageIcon\",\"@name\",\"@role\"],false,[\"link-to\",\"utils/circle-image\",\"if\"]]",
    "moduleName": "iosxpert-web/components/utils/role-linking-group/contact-linking-field/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});