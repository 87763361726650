define("iosxpert-web/templates/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YyB5+EWz",
    "block": "[[[10,0],[14,0,\"flex h-screen\"],[12],[1,\"\\n\\t\"],[10,0],[14,0,\"m-auto\"],[12],[1,\"\\n    \"],[8,[39,0],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"loading-spinner\"]]",
    "moduleName": "iosxpert-web/templates/loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});