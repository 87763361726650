define("iosxpert-web/components/item-list/footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rXqmjH0F",
    "block": "[[[10,0],[14,0,\"h-10 px-2 py-2 border-t-solid border-t border-gray-300 bg-gray-100 text-gray-400\"],[12],[1,\"\\n\"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "iosxpert-web/components/item-list/footer/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});