define("iosxpert-web/main/companies/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z7Qjvo7Q",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"Header\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"Title\"]],null,null,[[\"default\"],[[[[1,\"Companies\"]],[]]]]],[1,\"\\n    \"],[8,[30,2,[\"Subtitle\"]],null,null,[[\"default\"],[[[[1,\"Search \"],[1,[30,3,[\"length\"]]],[1,\" Companies\"]],[]]]]],[1,\"\\n    \"],[8,[30,2,[\"SearchBox\"]],null,null,[[\"default\"],[[[],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n  \"],[8,[30,1,[\"Body\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,3]],null]],null],null,[[[1,\"      \"],[8,[30,4,[\"Item\"]],null,[[\"@title\",\"@subtitle\",\"@route\",\"@model\"],[[29,[[30,5,[\"name\"]]]],[29,[[30,5,[\"addresses\",\"0\",\"city\"]],\" \",[30,5,[\"addresses\",\"0\",\"state\"]]]],\"main.companies.show\",[30,5]]],[[\"default\"],[[[[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[5]],null],[1,\"  \"]],[4]]]]],[1,\"\\n  \"],[8,[30,1,[\"Footer\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[1,[30,3,[\"length\"]]],[1,\" Companies\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"],[10,\"main\"],[14,0,\"w-full\"],[12],[1,\"\\n\"],[46,[28,[37,4],null,null],null,null,null],[1,\"\\n\"],[13]],[\"l\",\"h\",\"@model\",\"b\",\"company\"],false,[\"item-list\",\"each\",\"-track-array\",\"component\",\"-outlet\"]]",
    "moduleName": "iosxpert-web/main/companies/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});