define("iosxpert-web/components/general/card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jFrMDviT",
    "block": "[[[11,0],[17,1],[24,0,\"bg-white shadow-md hover:shadow-xl w-full rounded-xl p-4\"],[12],[1,\"\\n\"],[18,4,[[28,[37,1],null,[[\"Header\",\"Body\",\"Information\",\"Box\"],[[50,\"general/card/header\",0,null,null],[50,\"general/card/body\",0,null,[[\"model\",\"isEditing\"],[[30,2],[30,3]]]],[50,\"general/card/information\",0,null,[[\"model\",\"isEditing\"],[[30,2],[30,3]]]],[50,\"general/card/box\",0,null,[[\"model\",\"isEditing\"],[[30,2],[30,3]]]]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@model\",\"@isEditing\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "iosxpert-web/components/general/card/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});