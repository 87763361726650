define("iosxpert-web/components/login-panel/body/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MlvuJnKH",
    "block": "[[[10,\"main\"],[14,0,\"mb-auto mt-16\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"relative pt-6 px-4 sm:px-6 lg:px-8\"],[12],[1,\"\\n        \"],[18,2,[[28,[37,1],null,[[\"Login\"],[[50,\"login-panel/body/login\",0,null,[[\"startOAuth\"],[[30,1]]]]]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[\"@startOAuth\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "iosxpert-web/components/login-panel/body/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});