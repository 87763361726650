define("iosxpert-web/components/general/timeline/entry/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bN4/W3xq",
    "block": "[[[10,\"li\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"relative pb-8\"],[12],[1,\"\\n\\n    \"],[10,1],[14,0,\"line absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200\"],[14,\"aria-hidden\",\"true\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"relative flex space-x-3\"],[12],[1,\"\\n      \"],[10,0],[12],[1,\"\\n        \"],[10,1],[14,\"data-test-id\",\"icon-container\"],[15,0,[29,[\"h-8 w-8 rounded-full bg-\",[30,1],\"-500 flex items-center justify-center ring-8 ring-white\"]]],[12],[1,\"\\n          \"],[8,[39,0],[[24,\"color\",\"white\"]],[[\"@icon\",\"@prefix\"],[[29,[[30,2]]],[29,[[30,3]]]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"min-w-0 flex-1 pt-1.5 flex justify-between space-x-4\"],[12],[1,\"\\n        \"],[10,0],[12],[1,\"\\n          \"],[10,2],[14,0,\"text-sm text-gray-500\"],[12],[18,5,null],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,\"data-test-id\",\"date\"],[14,0,\"text-right text-sm whitespace-nowrap text-gray-500\"],[12],[1,\"\\n          \"],[10,\"time\"],[12],[1,[28,[35,2],[[30,4],\"ll\"],null]],[1,\" \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@color\",\"@icon\",\"@iconStyle\",\"@date\",\"&default\"],false,[\"fa-icon\",\"yield\",\"moment-format\"]]",
    "moduleName": "iosxpert-web/components/general/timeline/entry/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});