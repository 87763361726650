define("iosxpert-web/components/general/card/section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r5JJ/4Kc",
    "block": "[[[10,0],[14,0,\"sm:col-span-1\"],[12],[1,\"\\n\"],[10,\"dt\"],[14,0,\"text-sm font-medium text-gray-500\"],[12],[1,\"\\n  \"],[1,[30,1]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"dd\"],[14,0,\"mt-1 text-sm text-gray-900\"],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13],[1,\"\\n\"],[13]],[\"@title\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "iosxpert-web/components/general/card/section/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});