define("iosxpert-web/components/general/card/information/info-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BtskTur6",
    "block": "[[[41,[30,1],[[[10,0],[14,0,\"p-2 mx-2 whitespace-nowrap\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"w-4 h-4 px-1 mx-1 inline center sm:h-7\"],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"        \"],[8,[39,1],null,[[\"@icon\",\"@prefix\"],[[29,[[30,2]]],[30,0,[\"iconPrefix\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,1],[14,0,\"font-bold\"],[12],[1,[30,3]],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"],[41,[30,4],[[[1,\"        \"],[10,2],[14,0,\"inline\"],[12],[8,[39,2],null,[[\"@type\",\"@href\"],[[30,4],[30,1]]],[[\"default\"],[[[[1,[28,[35,3],[[30,1]],null]]],[]]]]],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,2],[14,0,\"inline\"],[12],[1,[30,1]],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[]],null]],[\"@value\",\"@icon\",\"@label\",\"@type\"],false,[\"if\",\"fa-icon\",\"utils/anchor\",\"shortener-url\"]]",
    "moduleName": "iosxpert-web/components/general/card/information/info-item/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});