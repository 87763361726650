define("iosxpert-web/components/sidebar/mobile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bPSg4qpm",
    "block": "[[[1,\"\\n\"],[10,0],[14,0,\"ml-4 w-4/5\"],[12],[1,\"\\n\"],[10,\"img\"],[14,0,\"w-8 m-2 h-8 ml-4 \"],[14,\"src\",\"/assets/images/iOWeb_BETA-icon.png\"],[14,\"alt\",\"iOWeb_BETA-icon\"],[12],[13],[1,\" \\n\"],[13],[1,\"\\n\\n \"],[11,0],[24,0,\"mx-4 my-2\"],[24,\"role\",\"button\"],[4,[38,0],[\"click\",[30,0,[\"showMenu\"]]],null],[12],[1,\"\\n    \"],[8,[39,1],[[24,\"color\",\"white\"]],[[\"@icon\",\"@prefix\",\"@size\"],[\"bars\",\"fas\",\"2x\"]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"menuVisible\"]],[[[1,\"    \"],[8,[39,3],null,[[\"@to\"],[[30,0,[\"wormholeID\"]]]],[[\"default\"],[[[[1,\"\\n\"],[1,\"        \"],[10,\"nav\"],[14,0,\"h-screen w-screen absolute\"],[12],[1,\"\\n            \"],[8,[39,4],null,null,null],[1,\"\\n            \"],[11,\"button\"],[24,1,\"mobile-nav-backdrop\"],[24,\"role\",\"button\"],[24,4,\"button\"],[4,[38,0],[\"click\",[30,0,[\"hideMenu\"]]],null],[4,[38,5],[\"Escape\"],null],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"on\",\"fa-icon\",\"if\",\"ember-wormhole\",\"sidebar\",\"on-key\"]]",
    "moduleName": "iosxpert-web/components/sidebar/mobile/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});