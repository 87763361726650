define("iosxpert-web/components/object-viewer/footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K16Ogc0C",
    "block": "[[[10,\"footer\"],[14,0,\"h-12 md:h-10 px-2 py-2 border-t-solid border-t border-gray-300 bg-gray-100 text-gray-400\"],[12],[1,\"\\n\"],[18,5,[[28,[37,1],null,[[\"Actions\"],[[50,\"object-viewer/footer/actions\",0,null,[[\"isEditing\",\"toggleEditing\",\"cancelEditing\",\"backward\"],[[30,1],[30,2],[30,3],[30,4]]]]]]]]],[1,\"\\n\"],[18,5,null],[1,\"\\n\"],[13]],[\"@isEditing\",\"@toggleEditing\",\"@cancelEditing\",\"@backward\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "iosxpert-web/components/object-viewer/footer/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});