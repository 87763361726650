define("iosxpert-web/components/utils/table/tfoot/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1rGYK+Ms",
    "block": "[[[11,0],[24,0,\"table-footer-group\"],[17,1],[12],[1,\"\\n    \"],[18,4,[[28,[37,1],null,[[\"Row\"],[[50,\"general/card/body/table/row\",0,null,[[\"model\",\"isEditing\"],[[30,2],[30,3]]]]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@model\",\"@isEditing\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "iosxpert-web/components/utils/table/tfoot/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});