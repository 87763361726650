define("iosxpert-web/components/general/views/image-and-text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eVaJV+z0",
    "block": "[[[11,0],[17,1],[24,0,\"text-center\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"pt-2 border-gray-200 m-auto mb-4\"],[12],[1,\"\\n        \"],[8,[39,0],[[24,0,\"h-20 w-20 rounded-full mx-auto\"]],[[\"@image\",\"@imageIcon\",\"@size\"],[[30,2],[30,3],\"4x\"]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"h2\"],[14,0,\"text-lg font-semibold text-gray-500\"],[12],[1,[30,4]],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@image\",\"@imageIcon\",\"@text\"],false,[\"utils/circle-image\"]]",
    "moduleName": "iosxpert-web/components/general/views/image-and-text/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});