define("iosxpert-web/components/general/keyword-group/keyword/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0uxeHtgv",
    "block": "[[[10,1],[14,0,\"border border-gray-300 px-3 rounded-md\"],[12],[1,[30,1]],[13]],[\"@name\"],false,[]]",
    "moduleName": "iosxpert-web/components/general/keyword-group/keyword/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});