define("iosxpert-web/components/object-viewer/header/actions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mSINIvOl",
    "block": "[[[10,0],[14,0,\"mt-6 flex object-none object-right-top flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3\"],[12],[1,\"\\n\"],[41,[30,1],[[[41,[30,2],[[[1,\"    \"],[8,[39,1],null,[[\"@action\",\"@title\"],[[30,3],\"Save Changes\"]],null],[1,\"\\n    \"],[8,[39,1],null,[[\"@action\",\"@title\"],[[30,4],\"Cancel\"]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,1],null,[[\"@action\",\"@title\"],[[30,3],\"Edit\"]],null],[1,\"\\n\"]],[]]]],[]],null],[13]],[\"@showEdit\",\"@isEditing\",\"@toggleEditing\",\"@cancelEditing\"],false,[\"if\",\"object-viewer/header/actions/button\"]]",
    "moduleName": "iosxpert-web/components/object-viewer/header/actions/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});