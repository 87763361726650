define("iosxpert-web/components/item-list/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vZwce4lC",
    "block": "[[[10,0],[14,0,\"px-6 pt-6 pb-4 border-b border-gray-200 rounded-sm bg-gray-100\"],[12],[1,\"\\n\"],[18,1,[[28,[37,1],null,[[\"Title\",\"Subtitle\",\"SearchBox\"],[[50,\"item-list/header/title\",0,null,null],[50,\"item-list/header/subtitle\",0,null,null],[50,\"item-list/header/search-box\",0,null,null]]]]]],[1,\"\\n\"],[13]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "iosxpert-web/components/item-list/header/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});