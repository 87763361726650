define("iosxpert-web/services/ember-oauth2", ["exports", "ember-oauth2/services/ember-oauth2"], function (_exports, _emberOauth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _emberOauth.default;
    }
  });
});