define("iosxpert-web/main/uitests/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UKL1DSJ/",
    "block": "[[[10,0],[14,0,\"w-full h-full relative order-first flex flex-col border-r border-gray-300\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@model\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"flex-1 relative z-0 overflow-y-auto bg-gray-200 pt-7\"],[12],[1,\"\\n\"],[1,\"\\n\\n\\n\\n\\n\\n\"],[1,\"          \"],[13],[1,\"\\n          \"],[10,\"footer\"],[14,0,\"h-10 px-2 py-2 border-t-solid border-t border-gray-300 bg-gray-100 text-gray-400\"],[12],[1,\"\\n            \"],[8,[30,2,[\"Footer\"]],null,null,[[\"default\"],[[[[1,\"\\n              \"],[8,[30,3,[\"Actions\"]],null,[[\"@showEdit\"],[true]],null],[1,\"\\n            \"]],[3]]]]],[1,\"\\n          \"],[13],[1,\"\\n        \"]],[2]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[\"@model\",\"o\",\"f\"],false,[\"object-viewer\"]]",
    "moduleName": "iosxpert-web/main/uitests/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});