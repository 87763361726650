define("iosxpert-web/mirage/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.post('/api/session', function () {
      localStorage.setItem('ember_simple_auth-session', "{\"authenticated\":{\"authenticator\":\"authenticator:iosxpert\",\"sessionID\":\"42179349-8A54-234234-f2f24-234\"}}");
      return {};
    });
    this.namespace = 'v1';
    this.get('/contacts', function (schema) {
      return schema.db.contacts;
    });
    this.get('/contacts/:id', function (schema, request) {
      var id = request.params.id;
      return schema.contacts.find(id);
    });
    this.get('/tasks', function (schema) {
      return schema.tasks.all();
    });
    this.get('/tasks/:id', function (schema, request) {
      var id = request.params.id;
      return schema.tasks.find(id);
    });
    this.get('/projects', function (schema) {
      return schema.db.projects.filter(function (project) {
        return !project.trashed;
      });
    });
    this.get('/projects/:id', function (schema, request) {
      var id = request.params.id;
      return schema.projects.find(id);
    });
    this.get('/companies', function (schema) {
      return schema.db.companies;
    });
    this.get('/companies/:id', function (schema, request) {
      var id = request.params.id;
      return schema.db.companies.find(id);
    });
    this.get('/categories', function (schema) {
      return schema.db.categories;
    });
    this.get('/users/:id', function (schema, request) {
      var id = request.params.id;
      return schema.db.users.find(id);
    });
  }
});