define("iosxpert-web/components/item-list/header/search-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uehQtncx",
    "block": "[[[1,\"\\n\"],[10,\"form\"],[14,0,\"mt-6 flex space-x-4\"],[14,\"action\",\"#\"],[12],[1,\"\\n\"],[10,0],[14,0,\"flex-1 min-w-0\"],[12],[1,\"\\n\\n  \"],[10,\"label\"],[14,\"for\",\"search\"],[14,0,\"sr-only\"],[12],[1,\"Search\"],[13],[1,\"\\n  \"],[10,0],[14,0,\"relative rounded-md shadow-sm\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none\"],[12],[1,\"\\n\"],[8,[39,0],null,[[\"@icon\",\"@prefix\"],[\"search\",\"fad\"]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"input\"],[14,3,\"search\"],[14,1,\"search\"],[14,0,\"block w-full py-3 pl-10 sm:text-sm border-gray-200 border rounded-md\"],[14,\"placeholder\",\"Search\"],[14,4,\"search\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[13]],[],false,[\"fa-icon\"]]",
    "moduleName": "iosxpert-web/components/item-list/header/search-box/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});