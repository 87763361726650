define("iosxpert-web/components/item-list/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o8TWISIs",
    "block": "[[[8,[39,0],null,[[\"@route\",\"@model\"],[[29,[[30,1]]],[30,2]]],[[\"default\"],[[[[1,\"\\n  \"],[10,\"li\"],[15,0,[29,[\"py-3 flex \",[52,[30,2,[\"dayliteCategory\"]],\"taskListCategory\"]]]],[15,5,[30,0,[\"dynamicCSSStyle\"]]],[12],[1,\"\\n    \"],[10,0],[14,0,\"ml-3\"],[12],[1,\"\\n      \"],[10,2],[14,0,\"title whitespace-nowrap overflow-ellipsis\"],[12],[1,[30,3]],[13],[1,\"\\n      \"],[10,2],[14,0,\"subtitle\"],[12],[1,[30,4]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"h-2\"],[12],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@route\",\"@model\",\"@title\",\"@subtitle\"],false,[\"link-to\",\"if\"]]",
    "moduleName": "iosxpert-web/components/item-list/item/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});