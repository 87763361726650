define("iosxpert-web/components/object-viewer/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GjiSnSad",
    "block": "[[[18,5,[[28,[37,1],null,[[\"Title\",\"Actions\"],[[50,\"object-viewer/header/title\",0,null,[[\"model\",\"isEditing\"],[[30,1],[30,2]]]],[50,\"object-viewer/header/actions\",0,null,[[\"isEditing\",\"toggleEditing\",\"cancelEditing\"],[[30,2],[30,3],[30,4]]]]]]]]],[1,\"\\n\"]],[\"@model\",\"@isEditing\",\"@toggleEditing\",\"@cancelEditing\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "iosxpert-web/components/object-viewer/header/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});