define("iosxpert-web/components/login-panel/body/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t+r3FDFG",
    "block": "[[[11,\"button\"],[24,0,\"mt-10 bg-gradient-to-r from-io-orange to-io-orange-lighter rounded-lg p-1 pl-14 pr-14 text-white\"],[24,4,\"button\"],[4,[38,0],[\"click\",[30,1]],null],[12],[1,\"\\nLogin\\n\"],[13]],[\"@startOAuth\"],false,[\"on\"]]",
    "moduleName": "iosxpert-web/components/login-panel/body/login/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});