define("iosxpert-web/components/utils/role-linking-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "df5n3lSx",
    "block": "[[[10,0],[14,0,\"py-2 text-left w-full mx-auto\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[10,2],[14,0,\"font-medium text-lg pl-7 text-gray-400 py-2\"],[12],[1,[30,1]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,0],[15,0,[29,[\"mx-auto px-6 grid lg:grid-cols-\",[30,0,[\"column\"]],\" sm:grid-cols-1 w-full gap-4\"]]],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"limitedRoles\"]]],null]],null],null,[[[1,\"            \"],[18,5,[[28,[37,4],null,[[\"ContactLinkingField\",\"ContactLinkingFieldSmale\",\"ProjectLinkingField\"],[[50,\"utils/role-linking-group/contact-linking-field\",0,null,[[\"image\",\"imageIcon\",\"name\",\"role\",\"contact\"],[[30,2,[\"contact\",\"image\"]],\"user\",[30,2,[\"contact\",\"name\"]],[30,2,[\"role\"]],[30,2,[\"contact\"]]]]],[50,\"utils/role-linking-group/contact-linking-field-smale\",0,null,[[\"image\",\"imageIcon\",\"name\",\"contact\"],[[30,2,[\"contact\",\"image\"]],\"user\",[30,2,[\"contact\",\"name\"]],[30,2,[\"contact\"]]]]],[50,\"utils/role-linking-group/project-linking-field\",0,null,[[\"name\",\"project\"],[[30,2,[\"project\",\"name\"]],[30,2,[\"project\"]]]]]]]]]],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n\"],[41,[28,[37,6],[[30,3,[\"length\"]],[30,4]],null],[[[1,\"        \"],[11,\"button\"],[24,0,\"w-full mx-auto border-t mt-4 text-sm\"],[24,4,\"button\"],[4,[38,7],[\"click\",[30,0,[\"showMoreToggle\"]]],null],[12],[41,[30,0,[\"showMoreCollapsed\"]],[[[1,\" Show top \"],[1,[30,4]]],[]],[[[1,\"Show \"],[1,[30,0,[\"showMoreCount\"]]],[1,\" more...\"]],[]]],[13],[1,\"\\n\"]],[]],null],[13]],[\"@linkingTitel\",\"item\",\"@roles\",\"@limit\",\"&default\"],false,[\"if\",\"each\",\"-track-array\",\"yield\",\"hash\",\"component\",\"gt\",\"on\"]]",
    "moduleName": "iosxpert-web/components/utils/role-linking-group/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});