define("iosxpert-web/components/login-panel/footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z3UpPW0N",
    "block": "[[[10,\"footer\"],[14,0,\"mb-16 mb-6 h-8\"],[12],[1,\"\\n\"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n      \"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "iosxpert-web/components/login-panel/footer/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});