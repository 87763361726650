define("iosxpert-web/components/login-panel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DcQdmTCe",
    "block": "[[[18,1,[[28,[37,1],null,[[\"Header\",\"Body\",\"Footer\"],[[50,\"login-panel/header\",0,null,null],[50,\"login-panel/body\",0,null,[[\"startOAuth\"],[[30,0,[\"startOAuth\"]]]]],[50,\"login-panel/footer\",0,null,null]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "iosxpert-web/components/login-panel/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});