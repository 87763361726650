define("iosxpert-web/components/detailcard/linked/invitations/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Achr8NUH",
    "block": "[[[10,\"tr\"],[14,0,\"flex flex-col flex-no sm:table-row mb-2 sm:mb-0\"],[12],[1,\"\\n \\t\"],[10,\"td\"],[14,0,\"sm:text-right text-gray-600 pl-4 sm:text-base text-sm align-top\"],[12],[1,\"invitations\"],[13],[1,\"\\n    \"],[10,\"td\"],[14,0,\"pr-5 pl-5 flex flex-col\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1,[\"invitedUsers\"]]],null]],null],null,[[[1,\"            \"],[10,0],[14,0,\"text-left flex flex-wrap\"],[12],[1,\"\\n                \"],[8,[39,2],null,[[\"@route\",\"@model\"],[\"main.contact\",[30,2,[\"contact\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[10,0],[14,0,\"border-grey-light border rounded-lg hover:bg-gray-100 p-1 pr-2 px-2 mb-2\"],[12],[1,\"\\n                    \"],[10,0],[14,0,\"table w-full\"],[12],[1,\"\\n                        \"],[10,0],[14,0,\"table-row-group\"],[12],[1,\"\\n                            \"],[10,0],[14,0,\"table-row\"],[12],[1,\"\\n                                \"],[10,0],[14,0,\"table-cell w-5\"],[12],[10,1],[14,0,\"material-icons text-base align-middle\"],[12],[1,\"person\"],[13],[1,\" \"],[13],[1,\"\\n                                \"],[10,0],[14,0,\"table-cell\"],[12],[1,[30,2,[\"contact\",\"firstName\"]]],[1,\" \"],[1,[30,2,[\"contact\",\"lastName\"]]],[1,\" \"],[1,[28,[35,3],[[28,[37,4],[[30,0,[\"userStatus\"]],[30,2]],null]],null]],[13],[1,\"\\n                                \"],[10,0],[14,0,\"table-cell w-4\"],[12],[10,1],[14,0,\"material-icons text-base align-middle\"],[12],[1,\"chevron_right\"],[13],[13],[1,\"\\n                            \"],[13],[1,\"  \\n                        \"],[13],[1,\"\\n                    \"],[13],[1,\"\\n                \"],[13],[1,\"\\n                \"]],[]]]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n\"],[13]],[\"@model\",\"invitee\"],false,[\"each\",\"-track-array\",\"link-to\",\"mut\",\"get\"]]",
    "moduleName": "iosxpert-web/components/detailcard/linked/invitations/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});