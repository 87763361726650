define("iosxpert-web/components/general/card/body/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zpzbt0jJ",
    "block": "[[[10,0],[15,0,[29,[\"w-auto py-4 px-6 items-center sm:col-span-\",[30,0,[\"columnSpan\"]]]]],[12],[1,\"\\n\"],[10,\"dt\"],[14,0,\"text-sm float-left w-1/2 font-bold text-gray-600\"],[12],[1,\"\\n  \"],[1,[30,1]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"dd\"],[14,0,\"mt-2 float-left w-1/2 text-sm text-gray-800\"],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13],[1,\"\\n\"],[13]],[\"@title\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "iosxpert-web/components/general/card/body/item/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});