define("iosxpert-web/components/general/card/body/text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jVYM5eBl",
    "block": "[[[41,[30,0,[\"effectiveEditMode\"]],[[[41,[30,0,[\"useTextarea\"]],[[[1,\"    \"],[8,[39,1],[[17,1],[24,0,\"p-1 duration-300 transition rounded border border-blue-200 focus:outline-none focus:ring focus:border-blue-300\"]],[[\"@value\"],[[30,0,[\"value\"]]]],null],[1,\"\"]],[]],[[[1,\"    \"],[8,[39,2],[[17,1],[24,0,\"p-1 duration-300 transition rounded border border-blue-200 focus:outline-none focus:ring focus:border-blue-300\"]],[[\"@value\"],[[30,0,[\"value\"]]]],null],[1,\"\\n\"]],[]]]],[]],[[[18,2,[[30,0,[\"value\"]]]],[1,\"\\n\"]],[]]]],[\"&attrs\",\"&default\"],false,[\"if\",\"textarea\",\"input\",\"yield\"]]",
    "moduleName": "iosxpert-web/components/general/card/body/text/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});