define("iosxpert-web/components/general/timeline/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yxkyWskO",
    "block": "[[[10,\"section\"],[14,\"aria-labelledby\",\"timeline-title\"],[14,0,\"lg:col-start-3 lg:col-span-1\"],[12],[1,\"\\n\\n\"],[10,0],[14,0,\"bg-white px-4 py-5 shadow sm:rounded-3xl sm:px-6\"],[12],[1,\"\\n\\n  \"],[10,\"h2\"],[14,1,\"timeline-title\"],[14,0,\"text-lg font-medium text-gray-900\"],[12],[1,[30,1]],[13],[1,\"\\n  \"],[10,0],[14,0,\"mt-6 flow-root\"],[12],[1,\"\\n    \"],[10,\"ul\"],[14,0,\"timeline\"],[12],[1,\"\\n      \"],[18,2,[[28,[37,1],null,[[\"Entry\"],[[50,\"general/timeline/entry\",0,null,null]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@title\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "iosxpert-web/components/general/timeline/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});