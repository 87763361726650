define("iosxpert-web/components/utils/table/row/cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fT4ZIs2t",
    "block": "[[[11,0],[24,0,\"table-cell py-1\"],[17,1],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "iosxpert-web/components/utils/table/row/cell/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});