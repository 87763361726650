define("iosxpert-web/components/quick-local-search-controller/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BudZjyE7",
    "block": "[[[8,[39,0],[[24,0,\"mt-3 ml-1 mr-1 mb-3 border rounded-lg border-grey-light\"]],[[\"@type\",\"@value\",\"@placeholder\"],[\"text\",[30,0,[\"searchValue\"]],\"search\"]],null],[1,\"\\n\\n\"],[41,[30,0,[\"isSearching\"]],[[[10,0],[12],[1,\"\\n    \"],[8,[39,2],null,null,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[]],[[[42,[28,[37,4],[[28,[37,4],[[30,0,[\"searchResult\"]]],null]],null],null,[[[1,\"    \"],[10,0],[14,0,\"border-grey-light border rounded-lg hover:bg-gray-100 p-1 pr-2 px-2 mb-2\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"table w-full\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"table-row-group\"],[12],[1,\"\\n                \"],[11,0],[24,0,\"table-row cursor-pointer\"],[24,\"role\",\"button\"],[4,[38,5],[\"click\",[28,[37,6],[[30,0,[\"onClickSearchResult\"]],[30,1]],null]],null],[12],[1,\"\\n                    \"],[10,0],[14,0,\"table-cell w-5\"],[12],[10,1],[14,0,\"material-icons text-base align-middle\"],[12],[1,[30,0,[\"materialIcon\"]]],[13],[1,\" \"],[13],[1,\"\\n                    \"],[10,0],[14,0,\"table-cell\"],[12],[1,[30,1,[\"displayName\"]]],[13],[1,\"\\n                \"],[13],[1,\"  \\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],null]],[]]]],[\"object\"],false,[\"input\",\"if\",\"loading-spinner\",\"each\",\"-track-array\",\"on\",\"fn\"]]",
    "moduleName": "iosxpert-web/components/quick-local-search-controller/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});