define("iosxpert-web/components/item-list/body/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F7q73Au/",
    "block": "[[[10,0],[14,0,\"flex-1 min-h-0 relative overflow-y-auto w-full\"],[12],[1,\"\\n\"],[10,\"ul\"],[14,0,\"item-list\"],[12],[1,\"\\n\"],[18,1,[[28,[37,1],null,[[\"Item\"],[[50,\"item-list/item\",0,null,null]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"],[13]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "iosxpert-web/components/item-list/body/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});