define("iosxpert-web/components/utils/role-linking-group/contact-linking-field-smale/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "75I1hH+5",
    "block": "[[[8,[39,0],[[24,0,\"flex items-center bg-gray-100 hover:bg-gray-50 py-1 rounded-lg float-left w-full h-full\"]],[[\"@route\",\"@model\"],[\"main.people.show\",[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],[[24,0,\"h-6 w-6 rounded-full float-left mx-1\"]],[[\"@image\",\"@imageIcon\"],[[30,2],[30,3]]],null],[1,\"\\n    \"],[10,0],[14,0,\"text-xs float-left mr-4 font-bold text-gray-600\"],[12],[1,[30,4]],[13],[1,\"\\n\"]],[]]]]]],[\"@contact\",\"@image\",\"@imageIcon\",\"@name\"],false,[\"link-to\",\"utils/circle-image\"]]",
    "moduleName": "iosxpert-web/components/utils/role-linking-group/contact-linking-field-smale/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});