define("iosxpert-web/components/object-viewer/footer/actions/button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JRJrptv3",
    "block": "[[[11,\"button\"],[17,1],[24,0,\"inline-flex items-center justify-end h-8 md:h-6 px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-lg text-gray-700 bg-white\"],[24,4,\"button\"],[16,\"disabled\",[30,0,[\"disabled\"]]],[4,[38,0],[\"click\",[28,[37,1],[[30,0,[\"handleClick\"]]],null]],null],[12],[1,\"\\n\"],[1,[30,2]],[1,\"\\n\"],[13]],[\"&attrs\",\"@title\"],false,[\"on\",\"fn\"]]",
    "moduleName": "iosxpert-web/components/object-viewer/footer/actions/button/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});