define("iosxpert-web/components/task-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m+aIsyj4",
    "block": "[[[1,\"\\n\"],[11,1],[24,\"role\",\"button\"],[24,0,\"material-icons cursor-pointer\"],[4,[38,0],[\"click\",[28,[37,1],[[30,0,[\"sortByKey\"]],\"title\"],null]],null],[12],[1,\"sort\"],[13],[1,\"\\n\"],[11,1],[24,\"role\",\"button\"],[24,0,\"material-icons cursor-pointer\"],[4,[38,0],[\"click\",[28,[37,1],[[30,0,[\"sortByKey\"]],\"due\"],null]],null],[12],[1,\"date_range\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"mb-2 table w-full sm:bg-white rounded-lg sm:shadow-lg my-5\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"table-row-group\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"sortTasks\"]]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"table-row\"],[12],[1,\"\\n\"],[1,\"          \"],[10,0],[14,0,\"table-cell w-5 taskListCategory p-1 pl-3\"],[15,5,[28,[37,4],[[28,[37,5],[\"color:\",[28,[37,5],[[30,1,[\"dayliteCategory\",\"hexColour\"]]],null]],null]],null]],[12],[8,[39,6],null,[[\"@type\",\"@checked\"],[\"checkbox\",[30,1,[\"done\"]]]],null],[13],[1,\"\\n          \"],[8,[39,7],null,[[\"@route\",\"@model\"],[\"main.task\",[30,1]]],[[\"default\"],[[[[10,0],[14,0,\"table-cell p-1 pl-3 cursor-pointer\"],[12],[1,[30,1,[\"title\"]]],[13]],[]]]]],[1,\"\\n          \"],[10,0],[14,0,\"table-cell p-1 w-5\"],[12],[1,[28,[35,8],[[30,1,[\"due\"]],\"DD.MM.YYYY\"],[[\"allow-empty\"],[true]]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"task\"],false,[\"on\",\"fn\",\"each\",\"-track-array\",\"html-safe\",\"concat\",\"input\",\"link-to\",\"moment-format\"]]",
    "moduleName": "iosxpert-web/components/task-list/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});