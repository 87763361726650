define("iosxpert-web/components/utils/anchor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tj/c55qf",
    "block": "[[[10,3],[15,6,[29,[[30,0,[\"typeAction\"]],[30,1]]]],[15,\"target\",[29,[[30,0,[\"target\"]]]]],[12],[18,2,null],[13]],[\"@href\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "iosxpert-web/components/utils/anchor/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});