define("iosxpert-web/components/sidebar/user/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oVaWWHL9",
    "block": "[[[10,0],[14,0,\"flex-shrink-0 h-10 px-2 py-2 flex border-t border-gray-300\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex items-center w-full\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex-none mx-auto h-6 w-6\"],[12],[1,\"\\n      \"],[10,\"img\"],[14,0,\"inline-block rounded-full\"],[14,\"src\",\"/assets/user-image.jpeg\"],[14,\"alt\",\"\"],[14,\"role\",\"none\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[30,1,[\"isExtended\"]],[[[1,\"    \"],[10,0],[14,0,\"flex-grow ml-3\"],[12],[1,\"\\n      \"],[10,2],[14,0,\"text-sm font-medium text-gray-700 group-hover:text-gray-900\"],[12],[1,\"\\n        \"],[1,[30,2]],[1,\" \"],[1,[30,3]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[11,\"button\"],[24,0,\"flex-none mx-auto inline-block bg-cover h-6 w-6\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"logout\"]]],null],[12],[8,[39,2],[[24,\"color\",\"white\"]],[[\"@icon\",\"@prefix\",\"@size\"],[\"sign-out\",\"fas\",\"1x\"]],null],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@sidebar\",\"@firstName\",\"@lastName\"],false,[\"if\",\"on\",\"fa-icon\"]]",
    "moduleName": "iosxpert-web/components/sidebar/user/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});