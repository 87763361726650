define("iosxpert-web/components/general/card/body/daylite-category/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e2PcKhPK",
    "block": "[[[8,[39,0],null,[[\"@title\"],[\"Category\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[8,[39,2],null,[[\"@class\",\"@options\",\"@selected\",\"@onChange\"],[\"categorySelect\",[30,0,[\"categories\"]],[30,2,[\"dayliteCategory\"]],[30,0,[\"selectedNewCategory\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,3],null,[[\"@category\"],[[30,3]]],null],[1,\"\\n    \"]],[3]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,3],null,[[\"@category\"],[[30,2,[\"dayliteCategory\"]]]],null],[1,\"\\n\"]],[]]]],[]]]]],[1,\"\\n\"]],[\"@isEditing\",\"@model\",\"category\"],false,[\"general/card/body/item\",\"if\",\"power-select\",\"utils/daylite-category\"]]",
    "moduleName": "iosxpert-web/components/general/card/body/daylite-category/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});