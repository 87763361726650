define("iosxpert-web/components/detailcard/spacer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vysBrCoM",
    "block": "[[[1,\" \"],[10,\"tr\"],[14,0,\"flex flex-col flex-no sm:table-row mb-4 sm:mb-2\"],[12],[1,\"\\n \\t\"],[10,\"td\"],[14,0,\"text-right sm:p-2\"],[12],[1,\" \"],[13],[1,\"\\n \\t\"],[10,\"td\"],[14,0,\"text-left sm:p-2\"],[12],[13],[1,\"\\n \"],[13]],[],false,[]]",
    "moduleName": "iosxpert-web/components/detailcard/spacer/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});