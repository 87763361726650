define("iosxpert-web/components/general/keyword-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aArGicIF",
    "block": "[[[18,1,[[28,[37,1],null,[[\"Keyword\"],[[50,\"general/keyword-group/keyword\",0,null,[[\"handler\"],[[30,0,[\"increment\"]]]]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "iosxpert-web/components/general/keyword-group/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});