define("iosxpert-web/components/detailcard/deleteBtn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pqXv1/m/",
    "block": "[[[11,1],[24,\"role\",\"button\"],[24,0,\"material-icons-outlined cursor-pointer text-base\"],[4,[38,0],[\"click\",[28,[37,1],[[30,0,[\"removeObject\"]],[30,1]],null]],null],[12],[1,\"cancel\"],[13]],[\"@value\"],false,[\"on\",\"fn\"]]",
    "moduleName": "iosxpert-web/components/detailcard/deleteBtn/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});