define("iosxpert-web/components/detailcard/linked/project/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oabxgFYG",
    "block": "[[[41,[28,[37,1],[[28,[37,2],[[30,1,[\"allProjects\"]]],null]],null],[[[10,\"tr\"],[14,0,\"flex flex-col flex-no sm:table-row mb-2 sm:mb-0\"],[12],[1,\"\\n \\t\"],[10,\"td\"],[14,0,\"sm:text-right text-gray-600 pl-4 sm:text-base text-sm align-top\"],[12],[1,\"All Projects\"],[13],[1,\"\\n    \"],[10,\"td\"],[14,0,\"pr-5 pl-5 flex flex-col\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,1,[\"allProjects\"]]],null]],null],null,[[[1,\"            \"],[10,0],[14,0,\"text-left flex flex-wrap\"],[12],[1,\"\\n                \"],[8,[39,5],null,[[\"@route\",\"@model\"],[\"main.project\",[30,2,[\"project\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[10,0],[14,0,\"hover:bg-gray-100 p-1 pr-2 px-2 mb-2\"],[12],[1,\"\\n                    \"],[10,0],[14,0,\"table w-full\"],[12],[1,\"\\n                        \"],[10,0],[14,0,\"table-row-group\"],[12],[1,\"\\n                            \"],[10,0],[14,0,\"table-row\"],[12],[1,\"\\n\"],[1,\"                                \"],[10,0],[14,0,\"table-cell w-5 taskListCategory p-1 pl-3\"],[15,5,[28,[37,6],[[28,[37,7],[\"color:\",[28,[37,7],[[30,2,[\"project\",\"dayliteCategory\",\"hexColour\"]]],null]],null]],null]],[12],[13],[1,\"\\n                                \"],[10,0],[14,0,\"table-cell p-1 pl-3\"],[12],[1,[30,2,[\"project\",\"name\"]]],[13],[1,\"\\n                                \"],[10,0],[14,0,\"table-cell p-1 w-5\"],[12],[1,[28,[35,8],[[30,2,[\"project\",\"due\"]],\"DD.MM.YYYY\"],[[\"allow-empty\"],[true]]]],[13],[1,\"\\n                            \"],[13],[1,\"  \\n                        \"],[13],[1,\"\\n                    \"],[13],[1,\"\\n                \"],[13],[1,\"\\n                \"]],[]]]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[]],null]],[\"@model\",\"project\"],false,[\"if\",\"not\",\"is-empty\",\"each\",\"-track-array\",\"link-to\",\"html-safe\",\"concat\",\"moment-format\"]]",
    "moduleName": "iosxpert-web/components/detailcard/linked/project/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});