define("iosxpert-web/components/detailcard/duedate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xh+Jbkox",
    "block": "[[[41,[28,[37,1],[[30,1],[28,[37,2],[[28,[37,3],[[30,2]],null]],null]],null],[[[10,\"tr\"],[14,0,\"flex flex-col flex-no sm:table-row mb-2 sm:mb-0\"],[12],[1,\"\\n\\t\"],[10,\"td\"],[14,0,\"sm:text-right text-gray-600 pl-4 sm:text-base text-sm\"],[12],[1,\"Due\"],[13],[1,\"\\n\"],[41,[30,1],[[[1,\"\\t\"],[10,\"td\"],[14,0,\"text-left pl-2\"],[12],[1,\"bitte editieren\"],[13],[1,\"\\n\"]],[]],[[[1,\"\\t\"],[10,\"td\"],[14,0,\"text-left flex items-start pr-5 pl-5\"],[12],[1,[28,[35,4],[[30,2],\"DD.MM.YYYY\"],null]],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[]],null]],[\"@editing\",\"@date\"],false,[\"if\",\"or\",\"not\",\"is-empty\",\"moment-format\"]]",
    "moduleName": "iosxpert-web/components/detailcard/duedate/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});