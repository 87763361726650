define("iosxpert-web/components/utils/table/row/label-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CqlDpEq2",
    "block": "[[[8,[39,0],[[17,1],[24,0,\"px-4 text-right font-semibold whitespace-nowrap\"]],null,[[\"default\"],[[[[1,\"\\n    \"],[18,2,null],[1,\"\\n\"]],[]]]]]],[\"&attrs\",\"&default\"],false,[\"utils/table/row/cell\",\"yield\"]]",
    "moduleName": "iosxpert-web/components/utils/table/row/label-cell/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});