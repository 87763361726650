define("iosxpert-web/components/utils/circle-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/2mW2HkL",
    "block": "[[[41,[30,0,[\"showImage\"]],[[[11,\"img\"],[17,1],[24,\"aria-hidden\",\"true\"],[16,\"src\",[29,[[30,2]]]],[12],[13],[1,\"\\n\"]],[]],[[[8,[39,1],[[17,1],[24,\"color\",\"white\"]],[[\"@icon\",\"@prefix\",\"@size\"],[[29,[[30,3]]],\"fad\",[30,4]]],null],[1,\"\\n\"]],[]]]],[\"&attrs\",\"@image\",\"@imageIcon\",\"@size\"],false,[\"if\",\"fa-icon\"]]",
    "moduleName": "iosxpert-web/components/utils/circle-image/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});