define("iosxpert-web/components/sidebar/desktop/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/QBZbBxX",
    "block": "[[[10,0],[14,0,\"hidden md:flex\"],[12],[1,\"\\n    \"],[8,[39,0],[[24,0,\"bg-gray-500\"]],null,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"sidebar\"]]",
    "moduleName": "iosxpert-web/components/sidebar/desktop/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});