define("iosxpert-web/components/header/left/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mYuyBemH",
    "block": "[[[10,0],[14,0,\"flex-none w-64\"],[12],[1,\"\\n    \"],[18,1,null],[1,\"\\n\"],[13]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "iosxpert-web/components/header/left/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});