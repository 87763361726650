define("iosxpert-web/components/general/card/body/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VxVoVHZs",
    "block": "[[[18,3,[[28,[37,1],null,[[\"Text\",\"Item\",\"DayliteCategory\",\"Table\"],[[50,\"general/card/body/text\",0,null,[[\"model\",\"isEditing\"],[[30,1],[30,2]]]],[50,\"general/card/body/item\",0,null,[[\"model\",\"isEditing\"],[[30,1],[30,2]]]],[50,\"general/card/body/daylite-category\",0,null,[[\"model\",\"isEditing\"],[[30,1],[30,2]]]],[50,\"utils/table\",0,null,[[\"model\",\"isEditing\"],[[30,1],[30,2]]]]]]]]],[1,\"\\n\"]],[\"@model\",\"@isEditing\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "iosxpert-web/components/general/card/body/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});