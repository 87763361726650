define("iosxpert-web/components/general/array-section-view/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S5lF/fmR",
    "block": "[[[41,[30,0,[\"array\"]],[[[1,\"    \\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"array\"]]],null]],null],null,[[[1,\"        \"],[18,3,[[30,1],[30,2]]],[1,\"\\n\"]],[1,2]],null]],[]],null]],[\"element\",\"index\",\"&default\"],false,[\"if\",\"each\",\"-track-array\",\"yield\"]]",
    "moduleName": "iosxpert-web/components/general/array-section-view/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});