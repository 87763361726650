define("iosxpert-web/components/sidebar/collapsed/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X0qIsHj4",
    "block": "[[[11,0],[24,0,\"absolute flex flex-wrap content-center inset-y-0 right-0 w-3 opacity-0 cursor-pointer hover:opacity-80 hover:bg-gray-200 mb-10 transition ease-in duration-200\"],[24,\"role\",\"button\"],[4,[38,0],[\"click\",[30,1,[\"toggleCollapsed\"]]],null],[12],[1,\"\\n  \"],[10,0],[14,\"data-test-id\",\"collapse-button\"],[12],[1,\"\\n\"],[41,[30,1,[\"collapsed\"]],[[[1,\"        \"],[8,[39,2],[[16,\"color\",[29,[[30,2]]]]],[[\"@icon\",\"@prefix\"],[\"chevron-right\",\"fas\"]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,2],[[16,\"color\",[29,[[30,2]]]]],[[\"@icon\",\"@prefix\"],[\"chevron-left\",\"fas\"]],null],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@sidebar\",\"@color\"],false,[\"on\",\"if\",\"fa-icon\"]]",
    "moduleName": "iosxpert-web/components/sidebar/collapsed/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});