define("iosxpert-web/components/utils/link-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s2bA9wRM",
    "block": "[[[10,0],[14,0,\"relative rounded-lg border border-gray-300 bg-gray px-4 py-2 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 \"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex-shrink-0\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"h-10 w-10 rounded-full bg-gray-600\"],[12],[1,\"\\n      \"],[10,\"img\"],[14,0,\"h-10 w-10 rounded-full\"],[15,\"src\",[29,[[30,1]]]],[14,\"alt\",\"\"],[14,\"role\",\"presentation\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex-1 min-w-0\"],[12],[1,\"\\n    \"],[8,[39,0],[[24,0,\"focus:outline-none\"]],[[\"@route\",\"@model\"],[[29,[[30,2]]],[30,3,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,1],[14,0,\"absolute inset-0\"],[14,\"aria-hidden\",\"true\"],[12],[13],[1,\"\\n      \"],[10,2],[14,0,\"text-sm font-medium text-gray-900\"],[12],[1,\"\\n        \"],[1,[30,4]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,2],[14,0,\"text-sm text-gray-500 truncate\"],[12],[1,\"\\n        \"],[1,[30,5]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@image\",\"@route\",\"@routeModel\",\"@title\",\"@subtitle\"],false,[\"link-to\"]]",
    "moduleName": "iosxpert-web/components/utils/link-button/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});