define("iosxpert-web/components/general/card/box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w0jIF4Cl",
    "block": "[[[11,0],[17,1],[24,0,\"w-full pt-4 pb-4\"],[12],[1,\"\\n    \"],[18,4,[[28,[37,1],null,[[\"Text\",\"Item\",\"DayliteCategory\",\"Table\"],[[50,\"general/card/body/text\",0,null,[[\"model\",\"isEditing\"],[[30,2],[30,3]]]],[50,\"general/card/body/item\",0,null,[[\"model\",\"isEditing\"],[[30,2],[30,3]]]],[50,\"general/card/body/daylite-category\",0,null,[[\"model\",\"isEditing\"],[[30,2],[30,3]]]],[50,\"utils/table\",0,null,[[\"model\",\"isEditing\"],[[30,2],[30,3]]]]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@model\",\"@isEditing\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "iosxpert-web/components/general/card/box/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});