define("iosxpert-web/main/projects/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G3AC3MX+",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"Header\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"Title\"]],null,null,[[\"default\"],[[[[1,\"Projects\"]],[]]]]],[1,\"\\n    \"],[8,[30,2,[\"Subtitle\"]],null,null,[[\"default\"],[[[[1,\"Search \"],[1,[30,0,[\"model\",\"length\"]]],[1,\" Projects\"]],[]]]]],[1,\"\\n    \"],[8,[30,2,[\"SearchBox\"]],null,null,[[\"default\"],[[[],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n  \"],[8,[30,1,[\"Body\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,4]],null]],null],null,[[[1,\"      \"],[8,[30,3,[\"Item\"]],null,[[\"@title\",\"@subtitle\",\"@route\",\"@model\"],[[29,[[30,5,[\"name\"]]]],[29,[[30,5,[\"status\"]]]],\"main.projects.show\",[30,5]]],[[\"default\"],[[[[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[5]],null],[1,\"  \"]],[3]]]]],[1,\"\\n  \"],[8,[30,1,[\"Footer\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[1,[30,0,[\"model\",\"length\"]]],[1,\" Projects\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"],[10,\"main\"],[14,0,\"w-full\"],[12],[1,\"\\n\"],[46,[28,[37,4],null,null],null,null,null],[1,\"\\n\"],[13]],[\"l\",\"h\",\"b\",\"@model\",\"project\"],false,[\"item-list\",\"each\",\"-track-array\",\"component\",\"-outlet\"]]",
    "moduleName": "iosxpert-web/main/projects/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});