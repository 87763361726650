define("iosxpert-web/helpers/shortener-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function shortenerUrl(params
  /*, hash*/
  ) {
    try {
      var url = new URL(params);
      return url.hostname;
    } catch (_) {
      return params;
    }
  });

  _exports.default = _default;
});