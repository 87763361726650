define("iosxpert-web/components/object-viewer/content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/cfOU4/k",
    "block": "[[[10,0],[14,0,\"flex-1 relative z-0 overflow-y-auto bg-gray-200 pt-7\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"container mx-auto\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"flex flex-row flex-wrap\"],[12],[1,\"\\n        \"],[18,3,[[28,[37,1],null,[[\"Section\"],[[50,\"object-viewer/content/section\",0,null,[[\"model\",\"isEditing\"],[[30,1],[30,2]]]]]]]]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[\"@model\",\"@isEditing\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "iosxpert-web/components/object-viewer/content/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});