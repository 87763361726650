define("iosxpert-web/components/detailcard/linked/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ywi2lJDR",
    "block": "[[[41,[28,[37,1],[[30,1,[\"invitedUsers\"]]],null],[[[1,\"\\t \"],[8,[39,2],null,[[\"@model\",\"@editing\",\"@searchEntity\"],[[30,1],[30,2],[30,3]]],[[\"default\"],[[[],[]]]]],[1,\"\\n\"]],[]],null],[41,[28,[37,1],[[30,1,[\"companyRoles\"]]],null],[[[1,\"\\t \"],[8,[39,3],null,[[\"@model\",\"@editing\",\"@searchEntity\"],[[30,1],[30,2],[30,3]]],[[\"default\"],[[[],[]]]]],[1,\"\\n\"]],[]],null],[41,[28,[37,1],[[30,1,[\"contactRoles\"]]],null],[[[1,\"\\t \"],[8,[39,4],null,[[\"@model\",\"@editing\",\"@searchEntity\"],[[30,1],[30,2],[30,3]]],[[\"default\"],[[[],[]]]]],[1,\"\\n\"]],[]],null],[41,[28,[37,1],[[30,1,[\"allCompanies\"]]],null],[[[1,\"\\t \"],[8,[39,5],null,[[\"@model\",\"@editing\",\"@searchEntity\"],[[30,1],[30,2],[30,3]]],[[\"default\"],[[[],[]]]]],[1,\"\\n\"]],[]],null],[41,[28,[37,1],[[30,1,[\"allContacts\"]]],null],[[[1,\"\\t \"],[8,[39,6],null,[[\"@model\",\"@editing\",\"@searchEntity\"],[[30,1],[30,2],[30,3]]],[[\"default\"],[[[],[]]]]],[1,\"\\n\"]],[]],null]],[\"@model\",\"@editing\",\"@searchEntity\"],false,[\"if\",\"is-array\",\"detailcard/linked/invitations\",\"detailcard/linked/company-role\",\"detailcard/linked/contact-role\",\"detailcard/linked/companies\",\"detailcard/linked/contacts\"]]",
    "moduleName": "iosxpert-web/components/detailcard/linked/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});