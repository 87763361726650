define("iosxpert-web/components/object-viewer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Aou+BMFD",
    "block": "[[[10,0],[14,0,\"w-full h-full relative order-first flex flex-col border-r border-gray-300\"],[12],[1,\"\\n\"],[18,2,[[28,[37,1],null,[[\"Header\",\"Footer\",\"Content\"],[[50,\"object-viewer/header\",0,null,[[\"isEditing\",\"toggleEditing\",\"cancelEditing\"],[[30,0,[\"isEditing\"]],[30,0,[\"toggleEditing\"]],[30,0,[\"cancelEditing\"]]]]],[50,\"object-viewer/footer\",0,null,[[\"isEditing\",\"toggleEditing\",\"cancelEditing\",\"backward\"],[[30,0,[\"isEditing\"]],[30,0,[\"toggleEditing\"]],[30,0,[\"cancelEditing\"]],[30,0,[\"backward\"]]]]],[50,\"object-viewer/content\",0,null,[[\"model\",\"isEditing\"],[[30,1],[30,0,[\"isEditing\"]]]]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"]],[\"@model\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "iosxpert-web/components/object-viewer/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});