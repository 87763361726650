define("iosxpert-web/components/task-list/task/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ULg//bY+",
    "block": "[[[10,\"li\"],[12],[1,\"\\n\\t\"],[10,0],[14,0,\"md:flex\"],[12],[1,\"\\n\\t\\t\"],[10,0],[14,0,\"md:flex-shrink-0\"],[12],[1,\"\\n  \\t\\t\\t\"],[8,[39,0],null,[[\"@type\",\"@checked\"],[\"checkbox\",[30,1,[\"done\"]]]],null],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\\t\\t\"],[10,0],[14,0,\"mt-6 md:mt-0 md:ml-6\"],[12],[1,\"\\n\\t\\t\\t\"],[10,0],[14,0,\"font-bold\"],[12],[1,[30,1,[\"title\"]]],[1,\"\\n\\t\\t\\t\"],[13],[1,\"\\n\\t\\t\\t\"],[10,0],[14,0,\"text-gray-600\"],[12],[1,\"einige Details\\n\\t\\t\\t\"],[13],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\\t\\t\"],[10,0],[14,0,\"mt-6 md:mt-0 md:ml-6\"],[12],[1,\"\\n\\t\\t\\t\"],[10,0],[14,0,\"text-blue-600 text-right\"],[12],[1,[28,[35,1],[[30,1,[\"due\"]],\"L\"],null]],[1,\"\\n\\t\\t\\t\"],[13],[1,\"\\n\\t\\t\\t\"],[10,0],[14,0,\"text-gray-600 text-right\"],[12],[41,[30,1,[\"location\"]],[[[1,\"(\"],[1,[30,1,[\"location\"]]],[1,\")\"]],[]],null],[1,\"\\n\\t\\t\\t\"],[13],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\\t\\t\"],[10,0],[14,0,\"mt-6 md:mt-0 md:ml-6\"],[12],[1,\"\\n      \"],[8,[39,3],null,[[\"@route\",\"@model\"],[\"main.task\",[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[10,\"button\"],[14,0,\"bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-1 px-2 rounded inline-flex items-center\"],[14,4,\"button\"],[12],[1,\"\\n          \"],[10,1],[12],[1,\"…\"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \\t\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@task\"],false,[\"input\",\"moment-format\",\"if\",\"link-to\"]]",
    "moduleName": "iosxpert-web/components/task-list/task/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});