define("iosxpert-web/components/object-viewer/header/actions/button/edit-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z9/7btik",
    "block": "[[],[],false,[]]",
    "moduleName": "iosxpert-web/components/object-viewer/header/actions/button/edit-button/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});