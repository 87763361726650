define("iosxpert-web/components/object-viewer/content/section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l9V17k/Q",
    "block": "[[[10,\"section\"],[14,0,\"w-full 2xl:w-1/2 mb-4 p-4 space-y-4\"],[12],[1,\"\\n\"],[18,3,[[28,[37,1],null,[[\"Card\"],[[50,\"general/card\",0,null,[[\"model\",\"isEditing\"],[[30,1],[30,2]]]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@model\",\"@isEditing\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "iosxpert-web/components/object-viewer/content/section/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});