define("iosxpert-web/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OlaU1C/g",
    "block": "[[[8,[39,0],null,[[\"@image\"],[\"/assets/images/loginbackground.png\"]],null],[1,\"\\n\"],[10,0],[14,0,\"overflow-y-auto\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"relative w-full md:w-full lg:w-8/12\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"flex flex-col h-screen justify-between\"],[12],[1,\"\\n        \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n          \"],[8,[30,1,[\"Header\"]],null,null,[[\"default\"],[[[[1,\"\\n              \"],[8,[39,2],[[24,0,\"object-cover m-4 h-20\"]],[[\"@image\"],[\"/assets/images/iOWeb_BETA.png\"]],null],[1,\"\\n          \"]],[]]]]],[1,\"\\n          \"],[8,[30,1,[\"Body\"]],null,null,[[\"default\"],[[[[1,\"\\n          \"],[10,0],[14,0,\"mt-6 mx-auto text-center\"],[12],[1,\"\\n            \"],[10,2],[14,0,\"text-3xl font-bold\"],[12],[1,\"Log in to Your Account\"],[13],[1,\"\\n            \"],[10,2],[14,0,\"mt-28\"],[12],[1,\"If you have a valid license, please login here\"],[13],[1,\"\\n            \"],[8,[30,2,[\"Login\"]],null,null,[[\"default\"],[[[],[]]]]],[1,\"\\n            \"],[10,2],[14,0,\"mt-28\"],[12],[1,\"If you still see this page after login, you might not have a valid license yet.\"],[10,\"br\"],[12],[13],[1,\"Please contact iOSXpert here\"],[13],[1,\"\\n            \"],[10,\"button\"],[14,0,\"mt-10 bg-gradient-to-r from-io-orange to-io-orange-lighter rounded-lg p-1 pl-14 pr-14 text-white\"],[14,4,\"button\"],[12],[1,\"Contact iOSXpert\"],[13],[1,\"\\n          \"],[13],[1,\"\\n          \"]],[2]]]]],[1,\"\\n          \"]],[1]]]]],[1,\"\\n      \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"loginPanel\",\"body\"],false,[\"utils/full-size-background\",\"login-panel\",\"utils/image\"]]",
    "moduleName": "iosxpert-web/login/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});