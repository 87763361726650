define("iosxpert-web/components/utils/daylite-category/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4ganVtRK",
    "block": "[[[41,[30,1],[[[10,1],[14,\"data-daylite-category-color\",\"\"],[14,0,\"pr-2 text-sm\"],[15,5,[28,[37,1],[[28,[37,2],[\"color: \",[30,1,[\"hexColour\"]]],null]],null]],[12],[1,\"●\"],[13],[1,\"\\n\"],[11,1],[24,\"data-daylite-category-name\",\"\"],[17,2],[12],[1,\"\\n  \"],[1,[30,1,[\"name\"]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[]],null]],[\"@category\",\"&attrs\"],false,[\"if\",\"html-safe\",\"concat\"]]",
    "moduleName": "iosxpert-web/components/utils/daylite-category/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});