define("iosxpert-web/components/object-viewer/header/title/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "65zlNiNd",
    "block": "[[[10,0],[14,0,\"rounded-3xl bg-white p-12\"],[12],[1,\"\\n\\t\"],[10,0],[14,0,\"\"],[12],[1,\"\\n\\n\\t\\t\\t\\n\\t\\t\"],[10,0],[14,0,\"relative mx-auto w-16 h-16\"],[12],[1,\"\\n\\t\\t\\t\\t\"],[8,[39,0],null,[[\"@image\",\"@imageIcon\"],[[30,1],[30,2]]],null],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\\t\\t\\t\\t\\t\\n\\t\\t\\t\"],[10,0],[14,0,\"text-center pt-4\"],[12],[1,\"\\n\\t\\t\\t\\t\"],[18,3,[[28,[37,2],null,[[\"Main\",\"Subtitle\"],[[50,\"object-viewer/header/title/main\",0,null,null],[50,\"object-viewer/header/title/subtitle\",0,null,null]]]]]],[1,\"\\n\\n\\t\\t\\t\"],[13],[1,\"\\n\\t\\t\\t\\t\\n\\t\\t\\t\\t  \\n\\t\\t\\t\\t\\t\\n\\t\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@image\",\"@imageIcon\",\"&default\"],false,[\"utils/circle-image\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "iosxpert-web/components/object-viewer/header/title/template.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});